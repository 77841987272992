<template>
  <v-footer dark padless>
    <v-col padless>
  
  
    
    <v-card  class="gray lighten-4 white--text text-center">

      <v-card-text>
        <v-btn v-for="icon in icons" :key="icon.icon" class="mx-4 white--text" icon>
          <v-btn class="mx-4 white--text" icon href="https://www.facebook.com/marathoexpresscourier" target="_blank">
            <v-icon size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-btn>
      </v-card-text>




     

        <v-col class="ma-5 justify-center  " xs="12" sm="12" md="12">
          <p>Feel free to reach us for your courier services from Dubai to Addis Ababa </p>

        </v-col>


    

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Marathon Express Couriers</strong>
      </v-card-text>
    </v-card>
      </v-col>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { icon: 'mdi-facebook', link: "https://www.facebook.com/marathoexpresscourier" },
      { icon: 'mdi-instagram', link: "https://www.facebook.com/marathoexpresscourier" },
    
    ],
  }),

 
}
</script>