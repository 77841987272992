<template>
  <v-container>
    <v-row justify="center">
      <v-col :justify="center">
              <v-img
                :src="require('../assets/marathon_logo.png')"
                class="mx-3"
                contain
                height="200"
              />
                <h2  class="text-md-center"><span class="grey--text">"Your reliable courier service provider."</span></h2>
              </v-col>

            <v-col>
              
                  <office_branches/>

            </v-col>

   
          
    </v-row>
    <front_carousels/>

     <v-col
        class="mb-5"
        cols="12"
      >   
    <numbers_stat/>
    </v-col>
 
    <location_map/>

   
    
  </v-container>
</template>

<script>
import office_branches from '../components/office_branches.vue'
import front_carousels from '../components/front_carousels.vue'
import location_map from '../components/location_map.vue'
import numbers_stat from '../components/numbers_stat.vue'


  export default {
    name: 'HelloWorld',

    components:{
    office_branches,
    front_carousels,
    location_map,
    numbers_stat,
    
    
},

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
