<template>
  <v-carousel cycle hide-delimiter-background
    show-arrows-on-hover>
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    >
    <div class="text-h2"></div>
    
    </v-carousel-item>
  </v-carousel>
</template>


<script>
  export default {
    data () {
      return {
        items: [
          {
            src: '/c_cover.jpg',
          },
          {
            src: '/c_kid.jpg',
          },
          {
            src: '/c_henos.jpg',
          },
          {
            src: '/c_elias.jpg',
          },
        ],
      }
    },
  }
</script>