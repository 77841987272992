<template>
  <v-app>


    <v-main>
      <HelloWorld/>
    </v-main>
    <Footer_cont/>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import Footer_cont from './components/footer_cont.vue';

export default {
  name: 'App',

  components: {
    HelloWorld,
    Footer_cont
},

  data: () => ({
    //
  }),
};
</script>
